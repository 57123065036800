const buildName = require("minimist")(process.argv.slice(2)).name;
const commonUrl = {
  title: "脑岛论坛",
  host: "localhost",
};
const testUrl = {
  ndUrl: "https://test.naodao.com/",
  winURL: "https://tforum.naodao.com", // 测试
  baseURL: "https://tforum.naodao.com/forum/", // 测试
  imgUrl: "https://insula-test.oss-cn-chengdu.aliyuncs.com/", // 测试
  pubUrl: "https://forum-pub-test.oss-cn-chengdu.aliyuncs.com/", // 测试
};

const proUrl = {
  ndUrl: "https://naodao.com/",
  baseURL: "https://forum.naodao.com/forum/", // 正式
  winURL: "https://forum.naodao.com", // 正式
  imgUrl: "https://insula.oss-cn-chengdu.aliyuncs.com/", // 正式
  pubUrl: "https://forum-pub.oss-cn-chengdu.aliyuncs.com/", // 正式
};
console.log(buildName);

// const mergeUrl =
//   buildName === "test" || !buildName
//     ? Object.assign(commonUrl, testUrl)
//     : Object.assign(commonUrl, proUrl);
const mergeUrl = Object.assign(commonUrl, proUrl)
console.log(mergeUrl);
module.exports = mergeUrl;
