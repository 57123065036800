// 用户接口
import request from "@/axios/request";
const baseURL = "/topic/user/";

/**
 * 用户登录(前台登录)
 * @param {params} 参数
 * @returns SUCCESS
 */
export function userLogin(params: any) {
  return request({
    url: `${baseURL}login`,
    method: "post",
    data: params,
  });
}
/**
 * 前台注册(前台注册)
 * @param {params} 参数
 * @returns SUCCESS
 */
export function userRegister(params: any) {
  return request({
    url: `${baseURL}register`,
    method: "post",
    data: params,
  });
}
/**
 * 关注用户
 * @param {userId} 参数
 * @returns SUCCESS
 */
export function followUser(id: any) {
  return request({
    url: `${baseURL}followUser/${id}`,
    method: "put",
  });
}
/**
 * 取消关注用户
 * @param {userId} 参数
 * @returns SUCCESS
 */
export function unFollowUser(id: any) {
  return request({
    url: `${baseURL}unfollowUser/${id}`,
    method: "put",
  });
}
/**
 * 获取粉丝列表
 * @param {pageNum,pageSize} params 参数
 * @returns SUCCESS
 */
export function getFensList(params: Object) {
  return request({
    url: `${baseURL}getFenListByPage`,
    method: "get",
    params,
  });
}
/**
 * 获取关注列表(废弃)
 * @param {pageNum,pageSize} params 参数
 * @returns SUCCESS
 */
export function getListFollow(params: Object) {
  return request({
    url: `${baseURL}getFollowListByPage`,
    method: "get",
    params,
  });
}
/**
 * 获取用户详情
 * @returns SUCCESS
 */
export function getUserInfo(params: Object) {
  return request({
    url: `${baseURL}getInfo`,
    method: "get",
    params,
  });
}
/**
 * 获取用户详情(游客模式使用)
 * @returns SUCCESS
 */
export function getUserVisitor(params: Object) {
  return request({
    url: `${baseURL}visitorDetail`,
    method: "get",
    params,
  });
}
/**
 * 获取某帖中作者信息
 * @param {userId} params 参数
 * @returns SUCCESS
 */
export function getQuestionInfo(params: Object) {
  return request({
    url: `${baseURL}getInfoAndQuestion`,
    method: "get",
    params,
  });
}
/**
 * 获取推荐用户列表（废弃）
 * @param {pageNum,pageSize} params 参数
 * @returns SUCCESS
 */
export function getRecommendList(params: Object) {
  return request({
    url: `${baseURL}getRecommendListByPage`,
    method: "get",
    params,
  });
}
/**
 * 获取所有推荐用户列表
 * @param {pageNum,pageSize} params 参数
 * @returns SUCCESS
 */
export function getRecommendAllList(params: Object) {
  return request({
    url: `${baseURL}listAllRecommendUser`,
    method: "get",
    params,
  });
}
/**
 * 修改用户信息
 * @param {params} 参数
 * @returns SUCCESS
 */
export function editUser(params: any) {
  return request({
    url: `${baseURL}updateInfo`,
    method: "put",
    data: params,
  });
}
/**
 * 当前用户退出
 * @param {params} 参数
 * @returns SUCCESS
 */
export function toLogout(params: any) {
  return request({
    url: `${baseURL}logout`,
    method: "put",
    data: params,
  });
}
/**
 * 发送短信验证码
 * @param {account, type} params 参数 type 1 注册 2 找回密码 4 实名认证
 * @returns SUCCESS
 */
export function sendCode(params: Object) {
  return request({
    url: `${baseURL}sendPhoneCode`,
    method: "get",
    params,
  });
}
/**
 * 实名认证
 * @param {} params 参数
 * @returns SUCCESS
 */
export function saveAuthentication(params: any) {
  return request({
    url: `${baseURL}realNameAuthentication`,
    method: "post",
    data: params,
  });
}
/**
 * 获取我关注的用户列表
 * @param {} params 参数
 * @returns SUCCESS
 */
export function getMyFollow(params: any) {
  return request({
    url: `${baseURL}pageMyFollow`,
    method: "post",
    data: params,
  });
}
/**
 * 获取用户经验活跃榜排名前10
 * @param {} params 参数
 * @returns SUCCESS
 */
export function getListRanking(params: any) {
  return request({
    url: `${baseURL}listUserTopTen`,
    method: "get",
    params,
  });
}
/**
 * 前台发帖或者评论@ 自己关注的用户
 * @param {} params 参数
 * @returns SUCCESS
 */
export function getMyNotifyMe(params: any) {
  return request({
    url: `${baseURL}notifyMe`,
    method: "post",
    data: params,
  });
}
/**
 * 获取我关注的用户列表
 * @param {} params 参数
 * @returns SUCCESS
 */
export function getPageMyFollow(params: any) {
  return request({
    url: `${baseURL}pageMyFollow`,
    method: "post",
    data: params,
  });
}
/**
 * 前台绑定用户邮箱
 * @param {} params 参数
 * @returns SUCCESS
 */
export function saveUserEmail(params: any) {
  return request({
    url: `${baseURL}bindUserEmail`,
    method: "post",
    data: params,
  });
}
/**
 * 邮件发送验证码
 * @param {} params 参数
 * @returns SUCCESS
 */
export function sendEmailCode(params: any) {
  return request({
    url: `${baseURL}sendEmailCode`,
    method: "post",
    data: params,
  });
}
/**
 * 找回密码
 * @param {} params 参数
 * @returns SUCCESS
 */
export function sendForget(params: any) {
  return request({
    url: `${baseURL}forget`,
    method: "post",
    data: params,
  });
}
/**
 * 用户昵称校验
 * @param {nickname} params 参数
 * @returns SUCCESS
 */
export function ruleNickname(params: any) {
  return request({
    url: `${baseURL}checkUserNickname`,
    method: "post",
    data: params,
  });
}
