import { createApp } from "vue";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/display.css";
import zhCn from "element-plus/lib/locale/lang/zh-cn"; // 中文
import en from "element-plus/lib/locale/lang/en"; // 英文
import I18n from "@/language/lang/index"; // 引入i18n组件
import VueCookies from "vue-cookies-ts";
import * as Icons from "@element-plus/icons-vue";
import vue3PreviewImage from "vue3-preview-image";

import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";

const app = createApp(App);

Object.keys(Icons).forEach((icon: any) => {
  app.component(icon, Icons[icon as keyof typeof Icons]);
});
let locale = "";
// 控制 element-plus 组件语言
if (I18n.global.fallbackLocale == "zh") {
  locale = zhCn as unknown as string;
} else {
  locale = en as unknown as string;
}
app.use(router);
app.use(store, key);
app.use(ElementPlus, { locale });
app.use(I18n);
// app.use(quillEmoji);
app.use(vue3PreviewImage);
// app.use(VueTextaSuggester);
app.config.globalProperties.$cookies = VueCookies;
app.mount("#app");
