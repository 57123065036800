"use strict";

import axios from "axios";
import { ElMessage } from "element-plus";
import { store } from "@/store";
import utils from "@/utils/cookie";

const defaultSettings = require("@/settings");
// 失效只弹一次
let num: any = 0;
const loginAgainFn = () => {
  num++;
  if (num < 2 && !utils.getCookie("isUrl")) {
    // ElMessage.error("登录失效");
  }
  setTimeout(() => {
    store.commit("deleteUser");
  }, 500);
};
axios.defaults.timeout = 3000000;
const service = axios.create({
  baseURL: defaultSettings.baseURL,
  // baseURL: process.env.NODE_ENV === "production" ? "http://47.108.158.211:21000/" : "/",
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 3000000, // request timeout
});

const requestRejected = (error: any) => {
  // do something with request error
  return Promise.reject(error);
};

// request interceptor
service.interceptors.request.use((config: any) => {
  // do something before request is sent
  if (process.env.NODE_ENV !== "production") {
    if (store.state.token) {
      config.headers["access-token"] = store.state.token;
    } else {
      if (utils.getCookie("actn")) {
        config.headers["access-token"] = utils.getCookie("actn");
      }
    }
  }
  config.headers.Accept = "*";
  return config;
}, requestRejected);

const responseFulfilled = (response: any) => {
  if (response.status === 401) {
    loginAgainFn();
    return;
  }
  // if (response.status === 504) {
  //   loginAgainFn();
  //   return;
  // }
  const res = response.data || response;
  if (res.code === "SUCCESS" || res.code == 200) {
    return res;
  } else {
    if (res.msg || res.desc) {
      ElMessage.error(res.msg ? res.msg : res.desc);
      return Promise.reject(new Error(res.msg ? res.msg : res.desc));
    } else {
      return res;
    }
  }
};

const responseRejected = (error: any) => {
  if (error.response) {
    const code = error.response.status;
    if (code === 401) {
      loginAgainFn();
      return;
    }
    // if (code === 504) {
    //   loginAgainFn();
    //   return;
    // }
  } else {
    ElMessage.error(error + "");
  }
  return Promise.reject(error);
};

// response interceptor
service.interceptors.response.use(responseFulfilled, responseRejected);

export default service;
