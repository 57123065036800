import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";
import utils from "@/utils/cookie";
import router from "@/router";
import { ElMessage } from "element-plus";
import { getNoReady } from "@/axios/topic/message";
import { getUserInfo, toLogout } from "@/axios/topic/user";

export interface State {
  token: String;
  userInfo: Object;
  postingData: Object;
  isMessage: Boolean;
  loginVisible: Boolean;
  allLoading: Boolean;
  matchedList: Array<any>; // 路由
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    token: "",
    userInfo: {},
    postingData: {},
    isMessage: false,
    loginVisible: false,
    allLoading: true,
    matchedList: [],
  },
  mutations: {
    async updateUser(state: any) {
      const tn: any = utils.getCookie("actn");
      try {
        const res: any = await getUserInfo({});
        if (res.code == "SUCCESS") {
          const datas: any = res.data;
          state.token = tn;
          state.userInfo = datas || {};
          state.loginVisible = false;
        }
      } catch (err) {
        state.token = "";
        store.commit("deleteUser");
      }
    },
    // 链接跳转赋值
    updateUrlUser(state: any, data: any) {
      const tn: any = utils.getCookie("actn");
      state.token = tn;
      state.userInfo = data || {};
    },
    async deleteUser(state: any) {
      if (state.userInfo.userId) {
        const res: any = await toLogout({
          userId: state.userInfo.userId,
        });
        if (res.code == "SUCCESS") {
          state.token = "";
          state.userInfo = {};
          utils.clearAllCookies();
          router.replace("/");
          store.commit("loginState", true);
        } else {
          ElMessage.error("退出失败");
        }
      } else {
        state.token = "";
        state.userInfo = {};
        utils.clearAllCookies();
        router.replace("/");
        store.commit("loginState", true);
      }
    },
    updatePosting(state: any, data: any) {
      state.postingData = data;
    },
    loginState(state: any, data: any) {
      state.loginVisible = data;
    },
    // 全局刷新页面
    updatePage(state: any, data: any) {
      state.allLoading = data;
    },
    // 全局路由
    updateUrl(state: any, data: any) {
      state.matchedList.push(data);
    },
    // 清除路由
    deleteUrl(state: any, data: any) {
      if (data) {
        state.matchedList = data;
      } else {
        state.matchedList = [];
      }
    },
    async readMessage(state: any) {
      const res: any = await getNoReady();
      if (res.code == "SUCCESS") {
        state.isMessage = res.data || false;
      }
    },
  },
});
