// 消息接口
import request from "@/axios/request";
const baseURL = "/topic/message/";

/**
 * 获取消息详情
 * @param {id} 参数
 * @returns SUCCESS
 */
export function getMessageInfo(id: any) {
  return request({
    url: `${baseURL}${id}`,
    method: "get",
  });
}
/**
 * 读消息
 * @param {id} 参数
 * @returns SUCCESS
 */
export function readMessage(id: any) {
  return request({
    url: `${baseURL}${id}`,
    method: "put",
  });
}
/**
 * 删除消息
 * @param {id} 参数
 * @returns SUCCESS
 */
export function delMessage(id: any) {
  return request({
    url: `${baseURL}${id}`,
    method: "delete",
  });
}
/**
 * 批量删除消息
 * @param {ids} params 参数
 * @returns SUCCESS
 */
export function delMessageList(params: Object) {
  return request({
    url: `${baseURL}batchDeleteMessage`,
    method: "post",
    data: params,
  });
}
/**
 * 获取消息列表
 * @param {pageNum,pageSize} params 参数
 * @returns SUCCESS
 */
export function getMessageList(params: Object) {
  return request({
    url: `${baseURL}getListByPage`,
    method: "get",
    params,
  });
}
/**
 * 获取是否有未读消息
 * @returns SUCCESS
 */
export function getNoReady() {
  return request({
    url: `${baseURL}noReady`,
    method: "get",
  });
}
/**
 * 批量读消息
 * @param {ids} params 参数
 * @returns SUCCESS
 */
export function readMsgList(params: Object) {
  return request({
    url: `${baseURL}batchReadMessage`,
    method: "post",
    data: params,
  });
}
