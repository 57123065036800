/**
 * 自定义公共函数
 */
const utils = {
  /**
   * @name: 设置cookie值
   * @author: camellia
   * @param:  cname   string  cookie名称
   * @param:  cvalue  any cookie值
   * @param:  exdays  number  cookie保存天数
   */
  setCookie(cname: string, cvalue: any, exdays = 720) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  },
  /**
   * @name: 获取cookie值
   * @author: camellia
   */
  getCookie(cname: string) {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1);
      if (c.indexOf(name) != -1) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  /**
   * @name: 清除cookie值
   * @author: camellia
   * @param:  cname   string  cookie名称
   */
  clearCookie(cname: string) {
    const d = new Date();
    d.setTime(-1);
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=''; " + expires;
  },
  /**
   * @name: 清除所有值
   * @author: camellia
   * @param:  cname   string  cookie名称
   */
  clearAllCookies() {
    const keys = document.cookie.match(/[^ =;]+(?==)/g);
    if (keys) {
      for (let i = keys.length; i--;) {
        document.cookie =
          keys[i] + "=0;path=/;expires=" + new Date(0).toUTCString(); // 清除当前域名下的,例如：m.ratingdog.cn
        document.cookie =
          keys[i] +
          "=0;path=/;domain=" +
          document.domain +
          ";expires=" +
          new Date(0).toUTCString(); // 清除当前域名下的，例如 .m.ratingdog.cn
        document.cookie =
          keys[i] +
          "=0;path=/;domain=naodao.com;expires=" +
          new Date(0).toUTCString(); // 清除一级域名下的或指定的，例如 .ratingdog.cn
      }
    }
  },
};
export default utils;
