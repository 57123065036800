// router.ts
// import { store } from "@/store";
// import utils from "@/utils/cookie";
import { createRouter, createWebHistory } from "vue-router";

const routerHistory = createWebHistory(process.env.BASE_URL);

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: "/login",
      name: "登录",
      component: () => import("@/views/login/loginIndex.vue"),
      // beforeEnter: (to, from, next) => {
      //   console.log("66666", to, from);
      // },
    },
    {
      path: "/realName",
      name: "实名认证",
      component: () => import("@/views/realName/realName.vue"),
    },
    {
      path: "/forget",
      name: "忘记密码",
      component: () => import("@/views/forget/forgetIndex.vue"),
    },
    {
      path: "/",
      name: "首页",
      component: () => import("@/views/homeIndex.vue"),
      children: [
        {
          path: "/",
          name: "首页",
          component: () => import("@/views/home/homePage.vue"),
        },
        {
          path: "/posting",
          name: "发帖详情",
          component: () => import("@/views/posting/postingMessage.vue"),
        },
        {
          path: "/postingInfo",
          name: "详情",
          component: () => import("@/views/postingInfo/infoIndex.vue"),
        },
        {
          path: "/violation",
          name: "违规帖",
          component: () => import("@/views/violations/violationsList.vue"),
        },
        {
          path: "/toIssue",
          name: "发帖提交",
          component: () => import("@/views/postForm/postingIndex.vue"),
        },
        {
          path: "/successPage",
          name: "提交成功",
          component: () => import("@/views/resultsPage/resultsSuccess.vue"),
        },
        {
          path: "/searchInfo",
          name: "搜索结果",
          component: () => import("@/views/search/searchInfo.vue"),
        },
        {
          path: "/advDetail",
          name: "公告详情",
          component: () => import("@/views/contact/detail/detailIndex.vue"),
        },
        {
          path: "/advList",
          name: "公告列表",
          component: () => import("@/views/contact/list/advList.vue"),
        },
        {
          path: "/authorInfo",
          name: "作者信息",
          component: () => import("@/views/homeTwo.vue"),
          children: [
            {
              path: "/authorInfo",
              name: "作者信息",
              component: () => import("@/views/userMain/author/authorInfo.vue"),
            },
            // {
            //   path: "/postingDetail",
            //   name: "详情",
            //   component: () => import("@/views/postingInfo/infoIndex.vue"),
            // },
          ],
        },
      ],
    },
    {
      path: "/userCenter",
      name: "个人主页",
      component: () => import("@/views/userCenter/userIndex.vue"),
      children: [
        {
          path: "/myArticle",
          name: "我的文章",
          component: () => import("@/views/userCenter/myArticle.vue"),
        },
        {
          path: "/myCollect",
          name: "我的收藏",
          component: () => import("@/views/userCenter/myCollect.vue"),
        },
        {
          path: "/myTransmit",
          name: "我的转发",
          component: () => import("@/views/userCenter/myTransmit.vue"),
        },
        {
          path: "/myAttention",
          name: "我的关注",
          component: () => import("@/views/userCenter/myAttention.vue"),
        },
        {
          path: "/myMessage",
          name: "我的消息",
          component: () => import("@/views/userCenter/myMessage.vue"),
        },
        {
          path: "/mySet",
          name: "我的设置",
          component: () => import("@/views/userCenter/mySet.vue"),
        },
      ],
    },
    {
      path: "/myArticle",
      name: "个人中心",
      component: () => import("@/views/userMain/userMain.vue"),
      children: [
        {
          path: "/integral",
          name: "积分信息",
          component: () => import("@/views/userMain/integral/integralInfo.vue"),
        },
        {
          path: "/personal",
          name: "修改个人信息",
          component: () =>
            import("@/views/userMain/personal/personalIndex.vue"),
        },
        {
          path: "/myFans",
          name: "我的粉丝",
          component: () => import("@/views/userMain/fans/myFans.vue"),
        },
      ],
    },
    {
      path: "/",
      redirect: "/",
    },
  ],
  // scrollBehavior: (to, from, savedPosition) => {
  //   if (savedPosition && to.meta.keepAlive) {
  //     return savedPosition;
  //   }
  //   return { x: 0, y: 0 };
  // },
});
// router.beforeEach((to, from, next) => {
//   // const info = utils.getCookie("actn");
//   // if (to.path == "/login") {
//   //   next();
//   // } else {
//   //   if (!info) {
//   //     next("/login");
//   //   } else {
//   //     next();
//   //   }
//   // }
//   // console.log("router");
//   // next();
// });

export default router;
